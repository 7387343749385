import React, { lazy } from "react";
import { Redirect } from "react-router-dom"; // eslint-disable-next-line
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Home/auth/Login/index")),
  },

  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/forget-password/index")
    ),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/reset-password/index")
    ),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/Verify-Otp/VerifyOtp")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Dashboard")),
  },

  {
    exact: true,
    path: "/terms-condition",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/Termcondition")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/Termcondition")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/tutorial-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/StaticView")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/notification-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/NotificationManagement/NotificationView"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/notification-edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/NotificationManagement/NotificationEdit"
      )
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/notification-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/NotificationManagement/NotificationManagement"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/StaticManagement"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdminManagement/AddDetails")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-event",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/Event/AddEvent")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/events-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/Event/EventList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/alumuni-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/Alumuni/AlumuniList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-alumuni",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/Alumuni/AddAlumuni")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/mentorsandteacher-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundCms/MentorsAndTeachers/MentorsAndTeachersList"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-mentorsandteacher",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundCms/MentorsAndTeachers/AddMentorsAndTeachers"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/webinar-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/Webinar/WebinarList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-webinar",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/Webinar/AddWebinar")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-static-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/AddStaticContent"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-static-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/ViewStaticContent"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-social",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/AddSocial")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/social-links",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/SocialLinks")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/tutorial-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/TutorialView")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/tutorial-edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/TutorialEdit")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/medical-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedicalEducation/MedicalEducationList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/user-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedicalEducation/ViewUser")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/settings",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Settings/Settings")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Settings/Changepassword")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/add-tutorial",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticContentManagement/Tutorial")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-gallery",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/GalleryManagement/AddGallery")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/gallery-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/GalleryManagement/GalleryManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/gallery-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/GalleryManagement/GalleryView")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/gallery-update",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/GalleryManagement/GalleryUpdate")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/banner-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BannerManagement/BannerManagementList")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/add-subadmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdminManagement/AddSubAdmin")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/subadmin-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdminManagement/SubManagementList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/banner-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BannerManagement/BannerView")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/banner-update",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BannerManagement/BannerUpdate")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-banner",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BannerManagement/AddBanner")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/update-story",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/StoryManagment/UpdateStory"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/notification-add",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/NotificationManagement/AddNotification")
    ),
  },

  // {
  //   exact: true,
  // guard: true,
  //   path: "/admin-management",
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/Profile/AdminProfile")
  //   ),
  // },

  // -------------admin------------------
  {
    exact: true,
    guard: true,
    path: "/medical-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedicalEducation/MedicalEducationList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/medbound-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/MedBoundCmsList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/editmedbound-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/EditMedBoundCms")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/medbound-times",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundTimes/MedBoundTimeList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/medbound-times-career",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundTimes/MedBoundTimesCareer/MedBoundTimeCareerList"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/editmedbound-times",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundTimes/EditMedBoundTime")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-career",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundTimes/MedBoundTimesCareer/AddMedBoundTimeCareer"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/AddFaq")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/ViewFaq")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/update-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/UpdateFaq")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/help-center-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HelpCenter/HelpCenterManagmentMain")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-helpcenter",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HelpCenter/AddHelpCenter")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-helpcenter",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HelpCenter/EditHelpCenter")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-helpcenter-queries",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HelpCenter/ViewHelpCenterQuery")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-helpcenter",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HelpCenter/ViewHelpCenter")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/update-helpcenter",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HelpCenter/UpdateHelpCenter")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/faq-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/FaqsMain")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/testimonial-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/TestimonialManagment/TestimonialManagment"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-testimonial",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/TestimonialManagment/AddTestimonial"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-testimonial",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/TestimonialManagment/ViewTestimonial"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/stories-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/StoryManagment/StoryManagment"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-stories",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/StoryManagment/AddStory"
      )
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/view-stories",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/StoryManagment/ViewStory"
      )
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/internship-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundCms/internshipbymed/InternshipList"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-internship",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundCms/internshipbymed/Editinternship"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-internship",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundCms/internshipbymed/Editinternship"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-internship",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundCms/internshipbymed/Editinternship"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/forum-by-social-clubs",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/forumbymed/Forums")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-forum",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/forumbymed/Addforums")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/blog-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/Bloglist/Bloglist")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/event-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/Event/EventList")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/blog-add",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/Bloglist/AddBlog")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/exam-module-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/ExamList/ExamModule")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/exam-list-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/ExamList/ExamlistView")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/module-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/ExamList/ExamModList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-exam-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/ExamList/Addexamlist")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-exam-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/ExamList/Addexamlist")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/flash-card",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/flashcardsec/FlashCard")
    ),
  },
  {
    // exact: true,
    guard: true,
    path: "/learning-tutorials",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundCms/LearningTutorialsCategory/LearningTutorials"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-category",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/flashcardsec/Addcategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-faq-category",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FaqManagement/AddFaqCategory")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/flashcard-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/flashcardsec/FlashCardList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-flashcard",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/flashcardsec/AddFlashCards")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-learningcategory",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundCms/LearningTutorialsCategory/AddCategory"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-learningtutorial",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundCms/LearningTutorialsCategory/AddTutorial"
      )
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/edit-category",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/flashcardsec/Addcategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/flashedit-category",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MedBoundCms/flashcardsec/EditCategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-sub-category",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/MedBoundCms/flashcardsec/AddsubCategory"
      )
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/edit-static-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/EditStaticContent"
      )
    ),
  },

  {
    exact: true,
    path: "/404",
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
