import React, { Suspense, Fragment, useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
import AuthContext from "src/context/Auth";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "src/theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const history = createBrowserHistory();

function App() {
  const theme = createTheme();

  // useEffect(() => {
  //   const handleContextmenu = (e) => {
  //     e.preventDefault();
  //   };
  //   document.addEventListener("contextmenu", handleContextmenu);
  //   return function cleanup() {
  //     document.removeEventListener("contextmenu", handleContextmenu);
  //   };
  // }, []);

  // useEffect(() => {
  //   window.onload = function () {
  //     document.addEventListener(
  //       "contextmenu",
  //       function (e) {
  //         e.preventDefault();
  //       },
  //       false
  //     );
  //     document.addEventListener(
  //       "keydown",
  //       function (e) {
  //         //document.onkeydown = function(e) {
  //         // "I" key
  //         if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
  //           disabledEvent(e);
  //         }
  //         // "J" key
  //         if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
  //           disabledEvent(e);
  //         }
  //         // "S" key + macOS
  //         if (
  //           e.keyCode == 83 &&
  //           (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
  //         ) {
  //           disabledEvent(e);
  //         }
  //         // "U" key
  //         if (e.ctrlKey && e.keyCode == 85) {
  //           disabledEvent(e);
  //         }
  //         // "F12" key
  //         if (e.keyCode == 123) {
  //           disabledEvent(e);
  //         }
  //       },
  //       false
  //     );

  //     function disabledEvent(e) {
  //       if (e.stopPropagation) {
  //         e.stopPropagation();
  //       } else if (window.event) {
  //         window.event.cancelBubble = true;
  //       }
  //       e.preventDefault();
  //       return false;
  //     }
  //   };
  // }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <ToastContainer />
            <Router history={history}>
              <RenderRoutes data={routes} />
            </Router>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
