// export const baseurl = "http://172.16.1.225:1967/"; //loca
// export const baseurl = "http://172.16.6.130:1967/"; //local

// export const baseurl = "https://node.medbound.com/"; // Live URL

export const baseurl = "https://node-medbound.mobiloitte.io/"; // staging

let user = `${baseurl}api/v1`;
let admin = `${user}/admin`;
let staticContent = `${user}/static`;
let faq = `${user}/faq`;
let Story = `${user}/Story`;
let gallery = `${user}/gallery`;
let webinar = `${user}/webinar`;
let forumClub = `${user}/forumClub`;
let Testimonial = `${user}/Testimonial`;
let exam = `${user}/exam`;
let internship = `${user}/internship`;
let subAdmin = `${user}/subAdmin`;
let medEduCMS = `${user}/medEduCMS`;
let event = `${user}/event`;
let banner = `${user}/banner`;
let tutorialVideo = `${user}/tutorialVideo`;
let tutorialVideoByCategory = `${user}/tutorialVideoByCategory`;
let blog = `${user}/blog`;
let files = `${user}/files`;
let helpCenterQuery = `${user}/helpCenterQuery`;
let helpCenterQuestion = `${user}/helpCenterQuestion`;
let mentor = `${user}/mentor`;
let FlashCard = `${user}/FlashCard`;
let career = `${user}/career`;

const ApiConfigs = {
  // admin Login

  login: `${admin}/userLogin`,
  resendOtp: `${admin}/resendOTP`,
  verifyOTP: `${admin}/verifyOTP`,
  resetPassword: `${admin}/resetPassword/`,
  profile: `${admin}/getProfile`,
  forgotPassword: `${admin}/forgotPassword`,
  changePassword: `${admin}/changePassword`,
  editProfile: `${admin}/editProfile`,

  //Dashboard
  dashboard: `${admin}/dashboard`,

  //exam
  listExam: `${exam}/listExam`,
  listExamModule: `${exam}/listExamModule`,
  AddExamModule: `${exam}/AddExamModule`,
  EditExamModule: `${exam}/EditExamModule`,
  BlockExamModeule: `${exam}/BlockExamModeule`,
  DeleteExamModeule: `${exam}/DeleteExamModeule`,
  viewExam: `${exam}/viewExam`,
  viewExamAbout: `${exam}/viewExamAbout`,
  editExamAbout: `${exam}/editExamAbout`,

  // internship
  listInternship: `${internship}/listInternship`,
  editinternship: `${internship}/editinternship`,
  blockUnblockInternship: `${internship}/blockUnblockInternship`,
  viewinternship: `${internship}/viewinternship`,
  addinternship: `${internship}/addinternship`,
  //forum / club
  listFroum: `${forumClub}/listFroum`,
  addFroumClub: `${forumClub}/addFroumClub`,
  editFroumClub: `${forumClub}/editFroumClub`,
  editFroum: `${forumClub}/editFroum`,
  editClub: `${forumClub}/editClub`,
  viewforumClub: `${forumClub}/viewforumClub`,
  DeleteFroumClub: `${forumClub}/DeleteFroumClub`,
  addClub: `${forumClub}/addClub`,
  // editClub: `${forumClub}/addClub`,
  //Vanner
  listBanner: `${banner}/listBanner`,
  addBanner: `${banner}/addBanner`,
  editBanner: `${banner}/editBanner`,
  viewBanner: `${banner}/viewBanner`,
  blockAndUnblockBnner: `${banner}/blockAndUnblockBnner`,
  DelateBnner: `${banner}/DelateBnner`,
  //blog
  listblog: `${blog}/blogList`,
  addBlog: `${blog}/addBlog`,
  blogEdit: `${blog}/blogEdit`,
  blogView: `${blog}/blogView`,
  blogDelete: `${blog}/blogDelete`,
  blogBlockUnblock: `${blog}/blogBlockUnblock`,

  //medEduCMS
  medEduCMSList: `${medEduCMS}/medEduCMSList`,

  //webinar
  addWebinar: `${webinar}/addWebinar`,
  listWebinar: `${webinar}/listWebinar`,
  viewWebinar: `${webinar}/viewWebinar`,
  editWebinar: `${webinar}/editWebinar`,
  deleteWebinar: `${webinar}/DeleteWebinar`,

  //listNewsLetter
  listNewsLetter: `${user}/newsLetter/listNewsLetter`,

  //medBoundCmsList
  medBoundCmsList: `${user}/medBoundCms/medBoundCmsList`,
  medBoundTimesList: `${user}/medBoundTimes/medBoundTimesList`,

  //subAdmin Management
  addSubAdmin: `${subAdmin}/addSubAdmin`,
  subAdminList: `${subAdmin}/subAdminList`,
  viewSubAdmin: `${subAdmin}/viewSubAdmin`,
  editSubAdmin: `${subAdmin}/editSubAdmin`,
  blockUnblockSubAdmin: `${subAdmin}/blockUnblockSubAdmin`,
  deleteSubAdmin: `${subAdmin}/deleteSubAdmin`,

  ///career/listCareer
  listCareer: `${career}/listCareer`,
  addcareer: `${career}/addcareer`,
  viewCareer: `${career}/viewCareer`,
  editcareer: `${career}/editcareer`,
  blocUnblockCareer: `${career}/blocUnblockCareer`,

  //Gallery Management
  listGallery: `${gallery}/listGallery`,
  addGallery: `${gallery}/addGallery`,
  DeleteGallery: `${gallery}/DeleteGallery`,
  BlockUnblockGallery: `${gallery}/BlockUnblockGallery`,
  editGallery: `${gallery}/editGallery`,
  viewGallery: `${gallery}/viewGallery`,

  //event Management
  listEvent: `${event}/listEvent`,
  addEvent: `${event}/addEvent`,
  viewevent: `${event}/viewEvent`,
  editevent: `${event}/editevent`,
  blocUnblockevent: `${event}/blocUnblockevent`,

  //Static Management
  staticContentList: `${staticContent}/staticContentList`,
  deleteStaticContent: `${staticContent}/deleteStaticContent`,
  addStaticContent: `${staticContent}/addStaticContent`,
  editStaticContent: `${staticContent}/editStaticContent`,

  //Faq Management
  addFAQ: `${faq}/addFAQ`,
  editFAQ: `${faq}/editFAQ`,
  faqList: `${faq}/faqList`,
  viewFAQ: `${faq}/viewFAQ`,
  deleteFAQ: `${faq}/deleteFAQ`,
  listFAQCategory: `${faq}/listFAQCategory`,
  deleteFAQCategory: `${faq}/deleteFAQCategory`,
  addFAQCategory: `${faq}/addFAQCategory`,
  editFAQCategory: `${faq}/editFAQCategory`,
  blockUnblockFAQCategory: `${faq}/blockUnblockFAQCategory`,

  //Story Management
  listStory: `${Story}/listStory`,
  deleteStory: `${Story}/deleteStory`,
  addStory: `${Story}/addStory`,
  viewStory: `${Story}/viewStory`,
  blockUnblockStory: `${Story}/blockUnblockStory`,
  editStory: `${Story}/editStory`,
  updateStoryFiles: `${Story}/updateStoryFiles`,
  viewStoryFiles: `${Story}/viewStoryFiles`,
  // multiple upload
  uploadFiles: `${files}/uploadFiles`,

  // cms medbound
  updateAbout: `${user}/medBoundCms/updateAbout`,
  medBoundCmsView: `${user}/medBoundCms/medBoundCmsView`,
  updateCampus: `${user}/medBoundCms/updateCampus`,
  medBoundTimesView: `${user}/medBoundTimes/medBoundTimesView`,
  updateBoundTimes: `${user}/medBoundTimes/updateAbout`,
  updateInternship: `${user}/medBoundTimes/updateInternship`,
  updateCareer: `${user}/medBoundTimes/updateCareer`,
  medEduCMSUpdateAbout: `${user}/medEduCMS/medEduCMSUpdateAbout`,
  medEduCMSViewAbout: `${user}/medEduCMS/medEduCMSViewAbout`,

  // -=------------------tutorialVideo-----------------

  addTutorialCategory: `${tutorialVideo}/addTutorialCategory`,
  tutorialCategoryList: `${tutorialVideo}/tutorialCategoryList`,
  tutorialCategoryView: `${tutorialVideo}/tutorialCategoryView`,
  tutorialCategoryEdit: `${tutorialVideo}/tutorialCategoryEdit`,
  tutorialCategoryDelete: `${tutorialVideo}/tutorialCategoryDelete`,
  tutorialCategoryBlockUnblock: `${tutorialVideo}/tutorialCategoryBlockUnblock`,
  addTutorialVideo: `${tutorialVideoByCategory}/addTutorialVideo`,
  tutorialVideoList: `${tutorialVideoByCategory}/tutorialVideoList`,
  tutorialVideoView: `${tutorialVideoByCategory}/tutorialVideoView`,
  tutorialVideoEdit: `${tutorialVideoByCategory}/tutorialVideoEdit`,
  tutorialVideoDelete: `${tutorialVideoByCategory}/tutorialVideoDelete`,
  tutorialVideoBlockUnblock: `${tutorialVideoByCategory}/tutorialVideoBlockUnblock`,
  tutorialVideoByCategoryId: `${tutorialVideoByCategory}/tutorialVideoByCategoryId`,

  //Mentor and teacher
  listMentor: `${mentor}/listMentor`,
  addMentor: `${mentor}/addMentor`,
  editMentor: `${mentor}/editMentor`,
  blockUnblockMentor: `${mentor}/blockUnblockMentor`,
  viewMentor: `${mentor}/viewMentor`,
  deleteMentor: `${mentor}/deleteMentor`,

  //Flash-Card

  addFlashcardCategory: `${FlashCard}/addFlashcardCategory`,
  flashcardCategoryList: `${FlashCard}/flashcardCategoryList`,
  flashcardCategoryView: `${FlashCard}/flashcardCategoryView`,
  flashcardCategoryEdit: `${FlashCard}/flashcardCategoryEdit`,
  flashcardCategoryDelete: `${FlashCard}/flashcardCategoryDelete`,
  flashcardCategoryBlockUnblock: `${FlashCard}/flashcardCategoryBlockUnblock`,
  addFlashcardSubCategory: `${FlashCard}/addFlashcardSubCategory`,
  flashcardSubCategoryList: `${FlashCard}/flashcardSubCategoryList`,
  flashcardSubCategoryView: `${FlashCard}/flashcardSubCategoryView`,
  flashcardSubCategoryEdit: `${FlashCard}/flashcardSubCategoryEdit`,
  flashcardSubCategoryDelete: `${FlashCard}/flashcardSubCategoryDelete`,
  flashcardSubCategoryBlockUnblock: `${FlashCard}/flashcardSubCategoryBlockUnblock`,
  addFlashcard: `${FlashCard}/addFlashcard`,
  viewFlashcard: `${FlashCard}/viewFlashcard`,
  editFlashcard: `${FlashCard}/editFlashcard`,
  deleteFlashcard: `${FlashCard}/deleteFlashcard`,
  blockUnblockFlashcard: `${FlashCard}/blockUnblockFlashcard`,
  listFlashcard: `${FlashCard}/listFlashcard`,

  // alumni section
  addAlumini: `${user}/alumini/addAlumini`,
  viewAlumini: `${user}/alumini/viewAlumini`,
  listAlumini: `${user}/alumini/listAlumini`,
  editAlumini: `${user}/alumini/editAlumini`,
  deleteAlumini: `${user}/alumini/deleteAlumini`,

  //------Help Cemter Management --------
  listHelpCenterQuery: `${helpCenterQuery}/listHelpCenterQuery`,
  viewHelpCenterQuery: `${helpCenterQuery}/viewHelpCenterQuery`,
  listHelpCenterQuestion: `${helpCenterQuestion}/listHelpCenterQuestion`,
  deleteHelpCenterQuestion: `${helpCenterQuestion}/deleteHelpCenterQuestion`,
  viewHelpCenterQuestion: `${helpCenterQuestion}/viewHelpCenterQuestion`,
  blockUnblockHelpCenterQuestion: `${helpCenterQuestion}/blockUnblockHelpCenterQuestion`,
  addHelpCenterQuestion: `${helpCenterQuestion}/addHelpCenterQuestion`,
  editHelpCenterQuestion: `${helpCenterQuestion}/editHelpCenterQuestion`,
  // viewHelpCenterQuestion: `${helpCenterQuestion}/viewHelpCenterQuestion`,

  // ----------Testimonial Managemnet -------------

  addTestimonial: `${Testimonial}/addTestimonial`,
  listTestimonial: `${Testimonial}/listTestimonial`,
  viewTestimonial: `${Testimonial}/viewTestimonial`,
  editTestimonial: `${Testimonial}/editTestimonial`,
  blockUnblockTestimonial: `${Testimonial}/blockUnblockTestimonial`,
  deleteTestimonial: `${Testimonial}/deleteTestimonial`,
};

export default ApiConfigs;
