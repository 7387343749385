import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Footer from "./Footer";
import { orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  loginLayoutBox: {
    background: theme.palette.background.white,
    display: "flex",
    zIndex: "9",
    position: "relative",
    justifyContent: "center",
    height: "100vh",
    // alignItems: 'center',
    "& .loginLayoutBoxImg": {
      width: "55%",
      zIndex: "1",
      position: "fixed",
      backgroundSize: "cover",
      // background: "#ff860d",
      backgroundImage: "url(./images/login_banner.jpeg)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: "100vh",
      left: 0,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      "& h1": {
        fontSize: "65px",
      },
    },
  },
  loginContentLayoutBox: {
    background: "#fff",
    position: "absolute",
    right: 0,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "65px 0 50px",
    },
  },
  MainLayout: {
    minHeight: "calc(100vh - 72px)",
    overflowX: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(history.location);
  return (
    // <div className={classes.root}>
    //   <div
    //     style={
    //       history.location.pathname !== "/"
    //         ? { display: "block" }
    //         : { display: "none" }
    //     }
    //   ></div>

    //   <div className={classes.MainLayout}>{children}</div>
    //   {/* <Footer /> */}
    // </div>

    <Box className={classes.loginLayoutBox}>
      <Box className="loginLayoutBoxImg">
        <Box align="center">
          <Typography variant="h4" style={{ color: "#fff" }}>
            Welcome to
          </Typography>
          <Box mt={1} mb={2}>
            <RouterLink
              to="/"
              style={{ textDecoration: "none" }}
            >
              <Typography variant="h1" style={{ color: "#fff" }}>
                MedBound
              </Typography>
            </RouterLink>
          </Box>

          <Typography variant="h4" style={{ color: "#fff" }}>
            {" "}
            Connecting WANTS & HAVES of Digital Skills!
          </Typography>
        </Box>
      </Box>
      <Box className={classes.loginContentLayoutBox}>
        <Box style={{ width: "100%" }}>{children}</Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
