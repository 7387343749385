import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
// import { calculateTimeLeft } from "../utils/index";
import ApiConfigs from "src/ApiConfig/ApiConfig";
// import { socketURL } from "src/apiConfig/config";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  // console.log("accessToken", accessToken);

  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  // console.log("isLogin", isLogin);
  // console.log("isLogin --- ", window.localStorage.getItem("token"));

  const getUserProfileDatahandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfigs.profile,
        headers: {
          token: token,
        },
      });
      if (res.data.responseCode === 200) {
        setUserData(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
      console.log(error.response.data.responseCode, "response ----- error");
      // if (error.response) {
      // eslint-disable-next-line
      if (error.response.data.responseCode == 401) {
        console.log(error.response, "response ----- error");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("tokens");
        // history.push("/");
      }
      // }
    }
  };
  useEffect(() => {
    let token = window.localStorage.getItem("token");
    if (token) {
      getUserProfileDatahandler(token);
    } // eslint-disable-next-line
  }, [window.localStorage.getItem("token")]);
  let data = {
    userLoggedIn: isLogin,
    userData,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    getUserProfileDatahandler: (data) => getUserProfileDatahandler(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
